<!-- 问卷调查 -->
<template>
  <div class="container">
    <div class="body">
      <span class="line"></span>
      <div class="tit">问卷调查</div>
      <div class="con">
        <ul>
          <li>
            <div class="line-tit">
              1. 是否有客服经历？<span class="line-check">【单选】</span>
            </div>
            <el-radio-group v-model="params.experience">
              <el-radio :label="0">1年内客服经验</el-radio>
              <el-radio :label="1">1-3年内客服经验</el-radio>
              <el-radio :label="2">3年以上客服经验</el-radio>
              <el-radio :label="3">无</el-radio>
            </el-radio-group>
          </li>
          <li>
            <div class="line-tit">
              2. 之前有没有从事过电商行业相关岗位？<span class="line-check"
                >【单选】</span
              >
            </div>
            <el-radio-group v-model="params.related">
              <el-radio :label="0">没有</el-radio>
              <el-radio :label="1">有，具体岗位名称为：</el-radio>
            </el-radio-group>
            <el-input
              class="ipt"
              v-model="params.jobs"
              placeholder="请输入内容"
            ></el-input>
          </li>
          <li>
            <div class="line-tit">
              3. 哪些网购平台使用比较多？<span class="line-check"
                >【多选】</span
              >
            </div>
            <el-checkbox-group v-model="shoppingPlatform">
              <div>
                <el-checkbox label="0">淘宝</el-checkbox>
                <el-checkbox label="1">京东</el-checkbox>
              </div>
              <div>
                <el-checkbox label="2" class="day">拼多多</el-checkbox>
                <el-checkbox label="3">抖音</el-checkbox>
              </div>
              <div>
                <el-checkbox label="4">快手</el-checkbox>
                <el-checkbox label="5">其他</el-checkbox>
              </div>
            </el-checkbox-group>
          </li>
          <li>
            <div class="line-tit">
              4. 对哪个电商类目感兴趣/熟悉？<span class="line-check"
                >【多选】</span
              >
            </div>
            <el-checkbox-group v-model="type">
              <div>
                <el-checkbox label="0">数码</el-checkbox>
                <el-checkbox label="1">女装</el-checkbox>
              </div>
              <div>
                <el-checkbox label="2" class="day">日用品</el-checkbox
                ><el-checkbox label="3">电器</el-checkbox>
              </div>
              <div>
                <el-checkbox label="4">食品</el-checkbox>
                <el-checkbox label="5">百货</el-checkbox>
              </div>
              <div>
                <el-checkbox label="6">男装</el-checkbox>
                <el-checkbox label="7">家居</el-checkbox>
              </div>
              <div>
                <el-checkbox label="8">美妆</el-checkbox>
                <el-checkbox label="9">运动户外</el-checkbox>
              </div>
            </el-checkbox-group>
          </li>
          <li class="mon">
            <div class="line-tit">
              5. 您的期望薪酬范围？<span class="line-check">【单选】</span>
            </div>
            <el-radio-group v-model="params.compensation">
              <div>
                <el-radio :label="0">4000以下</el-radio>
                <el-radio :label="1">4000-6000</el-radio>
              </div>
              <div>
                <el-radio :label="2">6000-8000</el-radio>
                <el-radio :label="3">8000-10000</el-radio>
              </div>
              <div>
                <el-radio :label="4">10000-15000</el-radio>
                <el-radio :label="5">15000-20000</el-radio>
              </div>
              <div>
                <el-radio :label="6">20000以上</el-radio>
              </div>
            </el-radio-group>
          </li>
          <li class="mon">
            <div class="line-tit">
              6. 你希望得到什么样的技术支持？
            </div>
            <el-input
              type="textarea"
              :rows="4"
              placeholder="请输入内容"
              v-model="params.technicalSupport"
            >
            </el-input>
          </li>
          <li class="foot">
            <el-button
              v-if="!flag"
              @click="
                () => {
                  joinCom();
                }
              "
              type="primary"
              >加入公司</el-button
            >
            <el-button
              @click="
                () => {
                  this.$router.push({ path: '/' });
                }"
              >{{flag? '返回':'跳过'}}</el-button
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
//import引入的组件需要注入到components中才能使用
import { questionnaire, view } from "../../service/login.js";
import { Config } from "../../utils/index.js";

export default {
  name: "questionnaire",
  components: {},
  props: {},
  data() {
    return {
      flag: this.$route.query.flag,
      adminId: this.$route.query.id,
      comId: this.$route.query.comId,
      params: {},
      shoppingPlatform: [], //平台
      type: [], //类目
      quest: {},
      haveQa: this.$sto.get(Config.constants.haveQa)
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    if (this.flag == "true") {
      this.getQaList();
    }
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  //方法集合
  methods: {
    // 查看
    async getQaList() {
      const { data } = await view({ adminId: this.adminId });
      this.quest = data;
      ////////////回显、、、、、
      this.params.experience = Number(data.experience);
      this.params.related = Number(data.related);
      this.params.jobs = data.jobs;
      this.shoppingPlatform = data.shopping_platform.split(",");
      this.shoppingPlatform = data.type.split(",");

      this.type = data.type.split(",");
      this.params.compensation = Number(data.compensation);
      this.params.technicalSupport = data.technical_support;
    },
    joinCom() {
      this.sendQa();
      this.$router.push({ path: "/index" });
    },
    // 填写
    async sendQa() {
      if (this.haveQa == "已填写调查问卷") {
        this.$router.push({path: '/index'})
        return this.$message.error("已填写问卷调查");
      } else {
        let params = { ...this.params };
        params.adminId = this.adminId;
        params.shoppingPlatform = this.shoppingPlatform.join(",");
        params.type = this.type.join(",");
        params.companyId = this.comId;
        const res = await questionnaire(params);
        if (res && res.data == "已填写调查问卷") {
          this.$sto.set(Config.constants.haveQa, "已填写调查问卷");
        }
        if (res.code == 200) {
          this.$message.success("操作成功");
        }
      }
    }
  }
};
</script>

<style lang="less" scoped>
.container {
  box-sizing: border-box;
  padding: 96px 291px;
  width: 100%;
  // height: 100vh;
  background-size: 100% 100%;
  background-color: #ebf0ff;
  background-image: url("../../assets/images/login_background.png");
  background-repeat: no-repeat;
  .body {
    box-sizing: border-box;
    padding: 80px;
    width: 100%;
    // height: 100%;
    background: #fff;
    .line {
      display: block;
      width: 40px;
      height: 6px;
      margin-bottom: 8px;
      background: #1890ff;
      border-radius: 8px;
    }
    .tit {
      margin-bottom: 80px;
      color: rgba(16, 16, 16, 100);
      font-size: 32px;
      text-align: left;
    }
    .con {
      li {
        text-align: left;
        margin-bottom: 32px;
        position: relative;
        .line-tit {
          font-size: 20px;
          color: #101010;
          margin-bottom: 16px;
        }
        .line-check {
          color: #1890ff;
          font-size: 20px;
        }
        .el-radio-group {
          display: flex;
          flex-direction: column;
          .el-radio {
            margin-top: 16px;
          }
        }
        .el-checkbox-group {
          display: flex;
          flex-direction: column;
          .el-checkbox {
            margin-top: 16px;
            margin-right: 160px;
          }
          .day {
            margin-right: 145px;
          }
        }
        /deep/.ipt {
          position: absolute;
          top: 84px;
          left: 167px;
          width: 1000px;
          .el-input__inner {
            border-radius: 0;
            border: none;
            border-bottom: 1px solid #bababa;
          }
        }
      }
      .mon {
        .el-radio {
          width: 180px;
        }
      }
      .foot {
        /deep/.el-button {
          width: 200px;
          span {
            font-size: 20px;
          }
        }
        /deep/.el-button--default {
          border: 1px solid #1890ff;
          span {
            color: #1890ff;
          }
        }
      }
    }
  }
}
</style>
